<template>
  <QrcodeVue v-bind="$attrs" :value="value"></QrcodeVue>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
export default {
  components: { QrcodeVue },
  props: ['value'],
};
</script>
