<template>
  <v-card flat tile :loading="loading">
    <v-container>
      <v-alert v-if="!loading && !account" type="error">
        Kein Konto vorhanden.
      </v-alert>
      <template v-if="account">
        <RowItem title="Kontoname kurz">
          <StaticText :value="account.samAccountName" />
        </RowItem>
        <RowItem title="Kontoname lang">
          <StaticText :value="account.userPrincipalName" />
          <CopyButton :value="account.userPrincipalName"></CopyButton>
        </RowItem>
        <RowItem title="Status">
          <LookupValue :value="account.status" />
          <span v-if="account.statusChangedOn"
            ><br /><DateValue :value="account.statusChangedOn"> </DateValue
          ></span>
          <span v-if="account.statusOnHold">gesperrt!</span>
          <v-btn
            v-if="account.awaitingCreation"
            color="primary"
            small
            class="ml-2"
            outlined
            @click="createAccount"
            :loading="creatingAccount"
            >erstellen</v-btn
          >
        </RowItem>
        <RowItem title="ID Person KINET">
          <StaticText :value="account.person.id" />
        </RowItem>
        <RowItem title="ID Person Evento">
          <StaticText :value="account.person.eventoId" />
        </RowItem>
        <RowItem title="Ausweis / Druckkarte"
          ><samp><StaticText :value="account.legicUid" /></samp>
          <CopyButton :value="account.legicUid"></CopyButton>
        </RowItem>
        <RowItem title="Anzahl gedruckte Ausweise">
          <StaticText :value="account.cardCount" />
        </RowItem>
        <RowItem title="Initialpasswort"
          ><samp>{{ account.initialPassword }}</samp></RowItem
        >
        <RowItem title="Reset-Token">
          <samp v-if="account.token">{{ account.token }}</samp>
          <v-btn icon>
            <v-icon small @click="deleteToken()">mdi-delete</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon small @click="createToken()">mdi-plus</v-icon>
          </v-btn>
        </RowItem>

        <RowItem title="Reset-Link" v-if="account.token"
          >https://password.gymkirchenfeld.ch/{{ account.token }}
          <CopyButton
            :value="'https://password.gymkirchenfeld.ch/' + account.token"
          ></CopyButton>

          <v-dialog width="570">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small>mdi-qrcode</v-icon>
              </v-btn>
            </template>

            <v-card class="d-flex pa-8">
              <QrCode
                size="500"
                :value="'https://password.gymkirchenfeld.ch/' + account.token"
              ></QrCode>
            </v-card>
          </v-dialog>
        </RowItem>

        <RowItem title="Aliase">
          <div v-for="alias in aliases" :key="alias.email">
            {{ alias.email }}
            <v-icon small v-if="alias.automatic">mdi-cog</v-icon>
          </div>
        </RowItem>
      </template>
    </v-container>
  </v-card>
</template>

<script>
import { saveChildRoute } from "@/router/helper";
import CopyButton from "@/components/CopyButton.vue";
import DateValue from "common/components/DateValue.vue";
import LookupValue from "common/components/LookupValue.vue";
import QrCode from "@/components/QrCode.vue";
import RowItem from "@/components/RowItem";
import StaticText from "common/components/StaticText.vue";

export default {
  name: "PersonAccount",
  components: {
    CopyButton,
    DateValue,
    LookupValue,
    QrCode,
    RowItem,
    StaticText,
  },
  props: {
    id: { type: String },
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      account: null,
      aliases: [],
      creatingAccount: false,
      loading: false,
    };
  },
  methods: {
    async createAccount() {
      this.creatingAccount = true;
      await this.apiPatch({
        resource: "account/account",
        id: this.account.id,
        key: "status",
        value: { id: 7 },
      });
      await this.apiPatch({
        resource: "job/job",
        id: 17,
        key: "runNow",
        value: true,
      });
      this.creatingAccount = false;
    },
    async getData() {
      if (this.personId) {
        this.loading = true;
        this.account = await this.apiList({
          resource: "account/account",
          query: "person=" + this.personId,
        });
        if (this.account) {
          this.aliases = await this.apiGet({
            resource: "account/alias",
            id: this.personId,
          });
        }
        this.loading = false;
      }
    },
    async deleteToken() {
      await this.apiPatch({
        resource: "account/account",
        id: this.account.id,
        key: "token",
        value: "delete",
      });
      this.account.token = null;
    },
    async createToken() {
      await this.apiPatch({
        resource: "account/account",
        id: this.account.id,
        key: "token",
        value: "create",
      });
      this.getData();
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
